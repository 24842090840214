<template>
  <div class="sign-in">
    <form action="" @submit="checkForm">
      <h2>ISHINER</h2>
      <div class="input">
        <input
          type="text"
          id="email"
          required
          v-model="email"
          @focus="onFocus"
          @blur="onBlur"
        />
        <label class="label" for="email">
          <span style="transition-delay: 0ms">E</span>
          <span style="transition-delay: 50ms">-</span>
          <span style="transition-delay: 100ms">m</span>
          <span style="transition-delay: 150ms">a</span>
          <span style="transition-delay: 200ms">i</span>
          <span style="transition-delay: 250ms">l</span>
        </label>
      </div>
      <div class="input">
        <input
          :type="pwdType"
          id="pwd"
          required
          v-model="password"
          @focus="onFocus"
          @blur="onBlur"
        />
        <label class="label" for="pwd">
          <span style="transition-delay: 0ms">P</span>
          <span style="transition-delay: 50ms">a</span>
          <span style="transition-delay: 100ms">s</span>
          <span style="transition-delay: 150ms">s</span>
          <span style="transition-delay: 200ms">w</span>
          <span style="transition-delay: 250ms">o</span>
          <span style="transition-delay: 300ms">r</span>
          <span style="transition-delay: 350ms">d</span>
        </label>
        <div class="view-icon">
          <el-icon v-if="showPwd" @click="hidePassword"><sunny /></el-icon>
          <el-icon v-else @click="showPassword"><sunrise /></el-icon>
        </div>
      </div>
      <input type="submit" value="LOGIN" id="btn" />
      <p class="forgot">
        <router-link to="/account/find-pwd">Forgot Password?</router-link>
      </p>
      <p>
        No Account?
        <router-link to="/account/sign-up">Create an Account</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import { userApi } from "../../api/index";

export default {
  name: "sign-in",
  data() {
    return {
      email: "",
      password: "",
      showMsg: false,
      msg: "",
      isOk: false,
      showPwd: false,
      pwdType: "password",
    };
  },
  methods: {
    // // 处理弹出提示，isError为false是背景色为green，否则为red
    // alertMsg(msg, isError = true) {
    //   this.showMsg = true;
    //   if (!isError) this.isOk = true;
    //   this.msg = msg;
    //   setTimeout(() => {
    //     this.showMsg = false;
    //   }, 3000);
    // },
    // 检查表单信息
    checkForm(e) {
      e.preventDefault();
      // email 的正则判断
      let emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
      if (!emailReg.test(this.email)) {
        this.$store.commit("alertMsg", {
          msg: "Check your e-mail format!",
          type: "error",
        });
        return;
      }
      // 发出登陆请求
      userApi
        .login({ email: this.email, password: this.password })
        .then((res) => {
          // 成功
          if (res.code == 200) {
            //token缓存到本地用于状态管理
            this.$store.commit("setToken", res.data.token);
            this.$store.commit("alertMsg", {
              msg: "Sucessfully login!",
              type: "ok",
            });
            // 获取用户的信息数据
            userApi.getInfo(res.data.token).then((res2) => {
              if (res2.code == 200) {
                //更新用户信息的本地缓存
                this.$store.commit("setInfo", res2.data);
                // 1s后跳转到原页面或首页
                setTimeout(() => {
                  this.$router.replace({
                    path: this.$route.query.redirect
                      ? this.$route.query.redirect
                      : "/home",
                  });
                }, 1000);
              } else {
                this.$store.commit("alertMsg", {
                  msg: "Network error!",
                  type: "error",
                });
              }
            });
          }
          // 错误处理
          else if (res.code == 4030) {
            this.$store.commit("alertMsg", {
              msg: "E-mail or password error!",
              type: "error",
            });
          } else if (res.code == 404) {
            this.$store.commit("alertMsg", {
              msg: "E-mail doesn't exist!",
              type: "error",
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: "Parameters lost!",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    onFocus(e) {
      e.target.classList.add("active");
    },
    onBlur(e) {
      if (!e.target.value.length) e.target.classList.remove("active");
    },
    showPassword() {
      this.pwdType = "text";
      this.showPwd = true;
    },
    hidePassword() {
      this.pwdType = "password";
      this.showPwd = false;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

form {
  padding: 30px;
  width: 400px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(18, 27, 89, 0.5);
  background: var(--color2-light);
  display: flex;
  flex-direction: column;
}

form h2 {
  margin: 20px 0;
  letter-spacing: 3px;
  color: var(--color1-dark);
}

form .input {
  width: 100%;
  margin: 25px 0;
  position: relative;
  font-weight: bold;
}

.input label {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}

.input label span {
  color: var(--color2-dark);
  font-size: 20px;
  display: inline-block;
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input input {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 0px;
  border: none;
  border-bottom: 2px solid var(--color2-dark);
  color: var(--color);
  background-color: var(--color2-light);
  box-shadow: inside 0 0 0 1000px var(--color2-light);
}

input.active + label span {
  transform: translateY(-150%);
  color: var(--color1-light);
}

input.active {
  outline: none;
  border-bottom: 3px solid var(--color1-light);
  color: var(--color1-dark);
}

#btn {
  height: 40px;
  margin: 30px 0;
  width: 300px;
  align-self: center;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(18, 27, 89, 0.5);
  border: none;
  cursor: pointer;
  font-size: 20px;
  /* color: white; */
  font-weight: bold;
}

#btn:hover {
  background: linear-gradient(to left, #8bbbed, #e8f4ff);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--color2-light) inset;
}

form p {
  margin: 10px 0;
}
form p a {
  text-decoration: none;
  padding: 0 5px;
  font-weight: bold;
  color: var(--color1-light);
}

.view-icon {
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
  color: var(--color1-dark);
  cursor: pointer;
}
</style>
